<template>
  <div class="table_material table_upc row">
    <!-- START OF UPC ALLOCATION SEASON SELECTOR -->
    <div>
      <h4 class="padding">Data Selection for UPC Allocation</h4>
      <p class="padding">
        If there is no historical booking or retailer sell-through, units are allocated at the UPC level using Market
        sell-through
      </p>
    </div>
    <div class="col-12">
      <div class="aselect" :data-value="value" :data-list="list.value">
        <div class="selector" @click="toggle()">
          <div class="label">
            <span>{{ value }}</span>
          </div>
          <div class="arrow" :class="{ expanded: visible }"></div>
          <div :class="{ hidden: !visible, visible }">
            <ul>
              <li
                :class="{ current: item === value }"
                v-for="item in list"
                :key="item.value"
                @click="selectUpcAllocation($event, item)"
              >
                {{ item.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 horizontal__scroll">
      <table class="table">
        <thead>
          <tr>
            <th class="labels_th">Summary</th>
            <th scope="col" v-for="month in months" :key="month.shortName">{{ month.shortName }}</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <tr class="heading">
                Material Level - Suggested Units
              </tr>
              <tr class="heading">
                Material Level - Suggested Gross
              </tr>
            </td>
            <td scope="col" v-for="(month, index) in months" v-once :key="index">
              <tr>
                {{
                  (getOrigPlannedUnitsByMonth(month.value) +
                    getSum(actuals, { selling_month: month.value }, "units_sold"))
                    | roundDecimals
                    | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  (getOrigPlannedPriceByMonth(month.value) + getSum(actuals, { selling_month: month.value }, "amount"))
                    | roundDecimals
                    | divideByThousand
                    | currencyFormat
                }}
              </tr>
            </td>
            <td class="previous">
              <tr>
                {{
                  (getOrigPlannedUnitsByYear() + getSum(actuals, {}, "units_sold")) | roundDecimals | commaSeparator
                }}
              </tr>
              <tr>
                {{
                  (getOrigPlannedPriceByYear() + getSum(actuals, {}, "amount"))
                    | roundDecimals
                    | divideByThousand
                    | currencyFormat
                }}
              </tr>
            </td>
          </tr>
          <tr class="bg-lightblue">
            <td>
              <tr class="heading">
                UPC Level - Adjusted Units
              </tr>
              <tr class="heading">
                UPC Level - Adjusted Gross
              </tr>
            </td>

            <td scope="col" v-for="(month, index) in months" :key="index">
              <tr :id="'mmpl-' + month.value"></tr>
              <tr :id="'mmpp-' + month.value"></tr>
            </td>
            <td>
              <tr id="ympl"></tr>
              <tr id="ympp"></tr>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 upcContainer">
      <div class="loading" v-if="loadIndicator && !loadFirstTime && this.tableUpcLoader">
        <Loading class="py-5" />
      </div>
      <div
        v-for="material in localMaterialData"
        :key="material.material"
        class="horizontal__scroll hideRows hideDetails"
        style="overflow: auto; display: block !important; max-height: 600px"
        :id="`material-${material.material}`"
        :set="(refMaterial = getData(refUpcs, { new_material_number: material.material }))"
      >
        <div class="tableTopInformation">
          &nbsp;&nbsp;<b
            >{{ material.description }} ({{ material.material }})&nbsp;&nbsp;&#x25CF;&nbsp;&nbsp;{{
              material.gender
            }}&nbsp;&nbsp;&#x25CF;&nbsp;&nbsp;{{ material.segment }}</b
          >&nbsp;&nbsp;&#x25CF;&nbsp;&nbsp;<b>WHSL: {{ material.amount }}</b>
        </div>
        <table class="table">
          <thead class="stickyTableHeader">
            <tr>
              <th class="no-border" colspan="35">
                <tr>
                  <a
                    href
                    @click.prevent="
                      toggleClass(
                        $event,
                        `material-${material.material}`,
                        'hideRows',
                        false,
                        getAllRefInfo(material.material)
                      )
                    "
                    >Toggle material breakdown</a
                  >
                </tr>
                <tr>
                  <a
                    href
                    @click.prevent="
                      toggleClass(
                        $event,
                        `material-${material.material}`,
                        'hideDetails',
                        true,
                        getAllRefInfo(material.material)
                      )
                    "
                    class="clr-red"
                    >Toggle previous sellthru
                  </a>
                </tr>
              </th>
            </tr>
            <tr>
              <th>
                <div class="current" style="font-weight: normal">Sum of Open and Planned</div>

                <div class="previous" style="font-weight: normal">
                  <div>Sellthru Reference 1</div>
                  <div>Sellthru Reference 2</div>
                </div>
              </th>
              <th
                v-for="i in monthArray"
                :key="i"
                colspan="2"
                :class="{ alignAndBorder: !isActiveData(material, months[i]), leftRightBorderDarker: true }"
              >
                <div class="current" v-if="isActiveData(material, months[i])">
                  {{ season.months[i] | dateRangeDescription }}
                  <br />
                  <span :id="'oap-' + material.material + '-' + adjustDateIndex(i)"> </span>
                </div>
                <div class="current" v-else>
                  {{ season.months[i] | dateRangeDescription }}
                  <br /><span :id="'oa-' + material.material + '-' + adjustDateIndex(i)"> </span>
                </div>
                <table class="previous" style="margin: auto">
                  <tr>
                    {{
                      getSum(
                        refSelthru,
                        {
                          material: refMaterial.ref_material,
                          year: getReferenceYearComputed("ref_material", refMaterial.ref_material, season.months[i]),
                          month: season.months[i],
                        },
                        "units_sold"
                      ) | isValidNumber
                    }}
                  </tr>
                  <tr>
                    {{
                      getSum(
                        refSelthru,
                        {
                          material: parseInt(refMaterial.ref_prev_material),
                          year: getReferenceYearComputed(
                            "ref_prev_material",
                            parseInt(refMaterial.ref_prev_material),
                            season.months[i]
                          ),
                          month: season.months[i],
                        },
                        "units_sold"
                      ) | isValidNumber
                    }}
                  </tr>
                </table>
              </th>
              <th colspan="2" class="allBorders">
                <div class="current" style="margin: auto; text-align: center">
                  <div>Total</div>
                  <div :id="'yop-' + material.material"></div>
                </div>
                <div class="previous" style="margin: auto; text-align: center">
                  <div>
                    {{ getSumForSelectedMonthsComputed("ref_material", refMaterial) | isValidNumber }}
                  </div>
                  <div>
                    {{ getSumForSelectedMonthsComputed("ref_prev_material", refMaterial) | isValidNumber }}
                  </div>
                </div>
              </th>
              <th>
                <div style="margin: auto; text-align: center">
                  <div>Gross Total</div>
                </div>
                <div style="margin: auto; text-align: center">
                  <div class="current"><span style="font-weight: bold" :id="'gsop-' + material.material"></span></div>
                  <div class="previous">--</div>
                  <div class="previous">--</div>
                </div>
              </th>
              <th>
                <div style="margin: auto; text-align: center; word-wrap: break-word">
                  <div>Stock Units {{ stockUnitsAsOf }}</div>
                </div>
                <div style="margin: auto; text-align: center">
                  <div class="current" style="margin: auto !important; text-align: center">
                    {{ getSum(stockUnits, { material_number: material.material }, "retailer_stock_units") }}
                  </div>

                  <div class="previous" style="margin: auto !important; text-align: center">
                    <div>
                      {{ getSum(stockUnits, { material_number: refMaterial.ref_material }, "retailer_stock_units") }}
                    </div>
                    <div>--</div>
                  </div>
                </div>
              </th>
              <th>
                <div style="margin: auto; text-align: center">
                  <div>Open Orders thru {{ currentSeasonYear }}-03-31</div>
                </div>
                <div style="margin: auto; text-align: center">
                  <div class="current" style="margin: auto !important; text-align: center">
                    {{
                      openOrders
                        .filter(
                          (item) =>
                            (item.request_year === season.year - 1 ||
                              (item.request_year === season.year &&
                                (item.request_month === 1 || item.request_month === 2 || item.request_month === 3))) &&
                            item.material === material.material
                        )
                        .reduce((total, item) => total + item.units, 0)
                    }}
                  </div>
                  <div class="previous" style="margin: auto !important; text-align: center">
                    <div>
                      {{
                        openOrders
                          .filter(
                            (item) =>
                              (item.request_year === season.year - 1 ||
                                (item.request_year === season.year &&
                                  (item.request_month === 1 ||
                                    item.request_month === 2 ||
                                    item.request_month === 3))) &&
                              item.material_number === refMaterial.ref_material
                          )
                          .reduce((total, item) => total + item.units, 0)
                      }}
                    </div>
                    <div>
                      {{
                        openOrders
                          .filter(
                            (item) =>
                              (item.request_year === season.year - 1 ||
                                (item.request_year === season.year &&
                                  (item.request_month === 1 ||
                                    item.request_month === 2 ||
                                    item.request_month === 3))) &&
                              item.material_number === refMaterial.ref_prev_material
                          )
                          .reduce((total, item) => total + item.units, 0)
                      }}
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th>
                <span style="font-weight: normal">Sum of Open or Planned</span><br />

                <span style="font-weight: bold; border-top: black 1px">Dim1&nbsp;-&nbsp;Dim2</span>
              </th>
              <template v-for="(item, index) in monthArray">
                <th
                  :key="index"
                  :colspan="isActiveData(material, months[index]) ? 1 : 2"
                  :class="{
                    alignAndBorder: !isActiveData(material, months[index]),
                    justLeftBorderDarker: isActiveData(material, months[index]),
                  }"
                >
                  O
                  <br style="border: 0px; margin: 0px" />
                  <span :id="'oo-' + material.material + '-' + adjustDateIndex(index)"> </span><br />&nbsp;
                </th>
                <th v-if="isActiveData(material, months[index])" :key="index + 'p'" class="justRightBorderDarker">
                  P
                  <br style="border: 0px; margin: 0px" />
                  <span :id="'m-' + material.material + '-' + adjustDateIndex(index)"> </span><br />
                  <span class="copy-blue">
                    <span :id="'mopi-' + material.material + '-' + adjustDateIndex(index)"> </span>
                  </span>
                </th>
              </template>
              <th class="allBorders" style="margin: auto; text-align: center">
                O<br />
                <span :id="'yom-' + material.material"></span><br />&nbsp;
              </th>
              <th style="margin: auto; text-align: center" class="allBorders">
                P<br />
                <span :id="'ypm-' + material.material"></span><br /><br />
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="showElements[`hideRows-material-${material.material}`]">
            <tr
              v-for="(variant, i) in getMaterial(material).upcs"
              :key="`${variant.upcNumber}_${i}`"
              :set="(refdata = getData(refUpcs, { new_upc_number: variant.upcNumber }))"
              class="paddingCell"
            >
              <th>
                <table class="current">
                  <tr style="font-weight: bold">
                    {{
                      variant.Dim1
                    }}<span style="font-weight: bold">&nbsp;{{ variant.Dim2 != "true" ? "-" : "" }}&nbsp;</span>
                    {{
                      variant.Dim2 === "true" ? "" : variant.Dim2
                    }}
                    <span style="text-align: right; font-weight: normal" v-if="isSellThruActive(material.material)"
                      >Open / Planned
                    </span>
                  </tr>
                </table>
                <table class="previous">
                  <tr>
                    {{
                      refdata.ref_description
                    }}
                    ({{
                      refdata.begin_algorithm_date
                    }}
                    -
                    {{
                      refdata.end_algorithm_date
                    }})
                  </tr>
                  <tr>
                    {{
                      refdata.ref_prev_description
                    }}
                    ({{
                      refdata.ref_prev_begin_algorithm_date2
                    }}
                    -
                    {{
                      refdata.ref_prev_end_algorithm_date
                    }})
                  </tr>
                </table>
              </th>
              <template v-for="(unit, index) in variant.months">
                <th
                  :key="index"
                  :colspan="isActiveData(material, months[index]) ? 1 : 2"
                  :class="{
                    alignAndBorder: !isActiveData(material, months[index]),
                    justLeftBorderDarker: isActiveData(material, months[index]),
                  }"
                >
                  <table class="current" style="margin: auto">
                    <tr>
                      <td colspan="2" style="padding: 0px !important">
                        {{
                          getSum(
                            actuals,
                            {
                              selling_month: season.months[index],
                              upc_number: variant.upcNumber,
                            },
                            "units_sold"
                          )
                        }}
                      </td>
                    </tr>
                  </table>
                  <table class="previous" style="margin: auto">
                    <tr>
                      <td
                        colspan="2"
                        style="padding: 0px !important"
                        :id="`refupc-${refdata.ref_upc}-${season.months[index]}-${adjustYear(
                          season.months[index],
                          new Date(refdata.begin_algorithm_date).getFullYear()
                        )}`"
                      >
                        <!--value set by getPreviousSellThruUnit when section is toggled visible -->
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="2"
                        style="padding: 0px !important"
                        :id="`refprevupc-${refdata.ref_prev_upc}-${season.months[index]}-${adjustYear(
                          season.months[index],
                          new Date(refdata.ref_prev_begin_algorithm_date2).getFullYear()
                        )}`"
                      >
                        <!--value set by getPreviousSellThruUnit when section is toggled visible -->
                      </td>
                    </tr>
                  </table>
                </th>
                <th
                  :key="index + 'extraCol'"
                  v-if="isActiveData(material, months[index])"
                  :colspan="1"
                  class="justRightBorderDarker"
                >
                  <div class="current">
                    <tr>
                      <td style="padding: 0px !important">
                        <input
                          type="number"
                          min="0"
                          :value="getUpcPlannedValue(material, variant, adjustDateIndex(index))"
                          @blur="setPlannedUpcValue(material, variant, adjustDateIndex(index), $event)"
                          class="isValid"
                          :disabled="!isActiveData(material, months[index])"
                        />
                        <span v-if="isSellThruActive(material.material)"><br /><br /><br /></span>
                      </td>
                    </tr>
                  </div>
                </th>
              </template>
              <th class="allBorders" colspan="1">
                <div class="current" style="margin: auto !important; text-align: center">
                  {{ getSum(actuals, { upc_number: variant.upcNumber }, "units_sold") | roundTwoDecimals }}
                </div>

                <div class="previous" style="margin: auto !important; text-align: center">
                  <div :id="`tot_ref_upc-${refdata.ref_upc}`"></div>
                  <div :id="`tot_ref_prev_upc-${refdata.ref_prev_upc}`"></div>
                </div>
              </th>
              <th :colspan="1" class="allBorders">
                <div class="current" style="margin: auto !important; text-align: center">
                  <span :id="'vpt-' + material.material + '-' + variant.upcNumber"></span>
                  <span v-if="isSellThruActive(material.material)"><br /><br /><br /></span>
                </div>
              </th>
              <th>
                <div class="current" style="margin: auto !important; text-align: center">
                  <span :id="'yvp-' + material.material + '-' + variant.upcNumber"></span>
                </div>
                <div class="previous" style="margin: auto !important; text-align: center">
                  <div>--</div>
                  <div>--</div>
                </div>
              </th>
              <th>
                <div class="current" style="margin: auto !important; text-align: center">
                  {{ getSum(stockUnits, { upc_number: variant.upcNumber }, "retailer_stock_units") }}
                </div>
                <div class="previous" style="margin: auto !important; text-align: center">
                  <div>
                    {{ getSum(stockUnits, { upc_number: refdata.ref_upc }, "retailer_stock_units") }}
                  </div>
                  <div>
                    {{
                      getSum(stockUnits, { upc_number: parseFloat(refdata.ref_prev_upc, 10) }, "retailer_stock_units")
                    }}
                  </div>
                </div>
              </th>
              <th>
                <div class="current" style="margin: auto !important; text-align: center">
                  {{
                    openOrders
                      .filter(
                        (item) =>
                          (item.request_year === season.year - 1 ||
                            (item.request_year === season.year &&
                              (item.request_month === 1 || item.request_month === 2 || item.request_month === 3))) &&
                          item.upc === variant.upcNumber
                      )
                      .reduce((total, item) => total + item.units, 0)
                  }}
                </div>
                <div class="previous" style="margin: auto !important; text-align: center">
                  <div>
                    {{
                      openOrders
                        .filter(
                          (item) =>
                            (item.request_year === season.year - 1 ||
                              (item.request_year === season.year &&
                                (item.request_month === 1 || item.request_month === 2 || item.request_month === 3))) &&
                            item.upc === refdata.ref_upc
                        )
                        .reduce((total, item) => total + item.units, 0)
                    }}
                  </div>
                  <div>
                    {{
                      openOrders
                        .filter(
                          (item) =>
                            (item.request_year === season.year - 1 ||
                              (item.request_year === season.year &&
                                (item.request_month === 1 || item.request_month === 2 || item.request_month === 3))) &&
                            item.upc === refdata.ref_upc
                        )
                        .reduce((total, item) => total + item.units, 0)
                    }}
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
        <button
          title="Scroll to top or bottom depending what location you are at in the table."
          v-show="showElements[`hideRows-material-${material.material}`]"
          id="scrollToTopBtn"
          @click="scrollToTop(`material-${material.material}`)"
        >
          &#8597;
        </button>
      </div>
      <div class="col-12 space-between">
        <div>
          <button class="btn button-secondary" @click="exportUpcData">Export UPC Totals To CSV</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { API, graphqlOperation } from "aws-amplify";

import Loading from "@/components/common/Loading";
import { filtersMixin } from "@/mixins/filters";

import { saveUpcData, deleteSavedUpcOrder } from "@/graphql/mutations";
import { loadUpcData, fetchUpc, getPreviousSellThruUnits, getAllReferenceUpcs, getRefYears } from "@/graphql/queries";

export default {
  mixins: [filtersMixin],
  data() {
    return {
      currentlyLoadedVariantMaterialNumber: "",
      value: "Choose your data",
      selectedValue: 0,
      visible: false,
      recompute: 0,
      // TODO REPLACE WITH STATE VARIABLES
      refYears: [],
      refUpcs: [],
      variantData: [],
      localMaterialIndex: [],
      localVariantData: [],
      localMaterialData: [],
      localMaterialFamily: [],
      localMaterialGender: [],
      localMaterialSegment: [],
      localMaterialDim: [],
      startIndex: 0,
      localMonthlyTotals: [],
      resetMaterial: false,
      filter: 111,
      loadIndicator: true,
      loadFirstTime: true,
      convertFraction: 0.4,
      monthArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      monthPositions: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
      pageError: "",
      isSaveDisabled: true,
      lastUpdated: "",
      pageErrorFreeze: "",
      lastUpdatedFreeze: "",
      refSelthru: [],
      loadedSellThruData: [],
      begin_season_filter_str: "BTH",
      showOpenAndClosedColumns: false,
      showElements: {},
      activeSellthruItems: [],
      remaindersByMaterialNumber: {},
      variantUnits: {},
      currentlyToggledPreviousSellThruMaterials: {},
      referenceYearComputedCache: {},
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "season",
      "product",
      "retailer",
      "months",
      "actuals",
      "suggestedBookings",
      "materialData",
      "historicGrowth",
      "inventory",
      "openOrders",
      "currentSeason",
      "previousSeasonsTotal",
      "currentSeasonMutation",
      "refreshData",
      "latestMonthCanPlan",
      "stockUnitsAsOf",
      "stockUnits",
      "currentSeasonYear",
      "earliestMonthCanPlanGlobal",
      "currentSeason",
      "tableUpcLoader",
      "sellThruData",
      "countryData",
      "marketData",
      "regionData",
    ]),

    startDate() {
      return `${this.season.year - 2}-04-01`;
    },
    endDate() {
      return `${this.season.year}-03-31`;
    },

    getSumForSelectedMonthsComputed() {
      return (type, data) => {
        let units = 0;

        for (let i = 1; i < 13; i++) {
          let year = this.adjustYear(i, new Date(data.begin_algorithm_date).getFullYear());
          let target = this.refSelthru.filter(
            (x) => x.material == data.ref_material && x.year === year && x.month === i
          );

          if (type === "ref_prev_material") {
            year = this.adjustYear(i, new Date(data.ref_prev_begin_algorithm_date2).getFullYear());
            target = this.refSelthru.filter(
              (x) => x.material == data.ref_prev_material && x.month === i && x.year === year
            );
          }

          units += target.reduce((total, item) => total + item.units_sold, 0);
        }

        return units;
      };
    },

    getMonthlyTotalsFromAllocation() {
      return (material, monthIndex) => {
        // console.log("getMonthlyTotalsFromAllocation");
        return material.data.reduce((acc, item) => acc + item.units[monthIndex], 0);
      };
    },

    setIfInvalidComputed() {
      return (units) => {
        if (Math.round(units) != units) {
          return "isInvalid";
        }
        return "isValid";
      };
    },
    checkDeadlineComputed() {
      return (month) => {
        var index = 0;
        if (typeof month.value !== "undefined" && month.value !== null) {
          index = month.value;
        } else {
          index = this.months.indexOf(month);
          if (index == -1) {
            index = month;
          }
        }
        if (index == 1 || index == 2 || index == 3) {
          return false;
        }
        if (index < this.earliestMonthCanPlanGlobal) {
          return true;
        } else {
          return false;
        }
      };
    },

    getPlannedUnitsByYear() {
      return () => {
        // console.log("getPlannedUnitsByYear");
        const val = this.$materialAllocation.plannedUnitsByYearForAllOrdertypes(true);
        return val;
      };
    },

    getOrigPlannedUnitsByMonth() {
      return (month) => {
        // console.log("getOrigPlannedUnitsByMonth");
        const val = this.$materialAllocation.plannedUnitsByMonthForAllOrdertypes(month, false);
        return val;
      };
    },
    getOrigPlannedPriceByMonth() {
      return (month) => {
        // console.log("getOrigPlannedPriceByMonth");
        const val = this.$materialAllocation.plannedPriceByMonthForAllOrdertypes(month, false);
        return val;
      };
    },

    getOrigPlannedPriceByYear() {
      return () => {
        // console.log("getOrigPlannedPriceByYear");
        const val = this.$materialAllocation.plannedPriceByYearForAllOrdertypes(false);
        return val;
      };
    },

    getOrigPlannedUnitsByYear() {
      return () => {
        // console.log("getOrigPlannedUnitsByYear");
        const val = this.$materialAllocation.plannedUnitsByYearForAllOrdertypes(false);
        return val;
      };
    },

    getOrigPlannedUnitsByMonthByMaterial() {
      return (month, material) => {
        // console.log("getOrigPlannedUnitsByMonthByMaterial");
        const val = this.$materialAllocation.getPlannedMaterialValue(
          material.type,
          material.segment,
          material.family,
          material.gender,
          material.material,
          month < 9 ? month + 4 : month - 8,
          false
        );
        return val;
      };
    },

    getPlannedUnitsByYearByMaterial() {
      return (material) => {
        // console.log("getPlannedUnitsByYearByMaterial");
        const val = this.$materialAllocation.plannedUnitsByYearForMaterialName(material, true);
        return val;
      };
    },

    getUpcPlannedValue() {
      return (material, variant, month) => {
        // console.log("getUpcPlannedValue");
        const val = this.$materialAllocation.getPlannedUpcValue(material.material, variant.upcNumber, month);
        return val;
      };
    },

    getScrollHeight(id) {
      let obj = document.getElementById(id);
      if (obj == null) {
        return 0;
      }
      return obj.scrollToTop;
    },
    isSellThruActive: function () {
      return function (id) {
        return this.activeSellthruItems.includes(id);
      };
    },
    isActiveData() {
      return (material, month) => {
        const columndate = new Date(month.value < 4 ? this.season.year + 1 : this.season.year, month.value - 1, 1);
        const sdate = this.getDateInEST(material.shelfdate);
        const adjustedShelfDate = new Date(sdate.getFullYear(), sdate.getMonth(), 1);
        return columndate >= adjustedShelfDate;
      };
    },
    isActiveDataByIndex() {
      return (material, month) => {
        const columndate = new Date(month < 4 ? this.season.year + 1 : this.season.year, month - 1, 1);
        const sdate = this.getDateInEST(material.shelfdate);
        const adjustedShelfDate = new Date(sdate.getFullYear(), sdate.getMonth(), 1);
        return columndate >= adjustedShelfDate;
      };
    },

    isActiveDataFromList() {
      return (mats, month) => {
        try {
          const min = mats.reduce((prev, current) => {
            return new Date(prev.shelfdate) < new Date(current.shelfdate) ? prev : current;
          });

          return this.isActiveData(min, month);
        } catch {
          return false;
        }
      };
    },

    list() {
      return [
        { description: "BTH Bookings", value: 111 },
        { description: "BTH Sell Thru (Retailer)", value: 112 },
        { description: "BTH Sell Thru (Region)", value: 114 },
        { description: "BTH Sell Thru (Country)", value: 113 },
        { description: "BTH Sell Thru (Market)", value: 115 },
        { description: "Last Saved Data", value: 777 },
      ];
    },

    getUnitsByMaterialIdFunc() {
      return (materialId, monthIndex) => {
        let total = 0;
        const customSavedAllocations = this.$store.state.customSavedAllocationValues[materialId] || [];
        const calculatedAllocations = this.$store.state.calculatedAllocationValues[materialId] || [];

        const customMonthValue = customSavedAllocations.find((x) => x["month_index"] === monthIndex);
        const calculatedMonthValue = calculatedAllocations.find((x) => x["month_index"] === monthIndex);

        if (customMonthValue) {
          total += customMonthValue["units_entered"];
        }

        if (calculatedMonthValue && (!customMonthValue || !customSavedAllocations.length)) {
          total += calculatedMonthValue.units;
        }
        return total;
      };
    },
  },
  components: {
    Loading,
  },
  watch: {
    // TODO THIS WILL NEED TO BE TRIGGERED BY AN EVENT
    refreshData: function (val) {
      if (val) {
        this.recalculateSummary();
        this.updateFinalUpcData();
      }
    },
  },
  created() {
    this.resetMaterialData();
    this.getAllReferenceUpcs();
    this.getRefYears();
    this.referenceYearComputedCache = {};
  },
  methods: {
    getAllRefInfo(material) {
      const target = this.refUpcs.filter((x) => x.new_material_number === material);
      return target.length === 0 ? "error" : target;
    },
    adjustDateIndex(i) {
      return i < 9 ? i + 4 : i - 8;
    },
    adjustYear(month, year) {
      return month < 4 ? year + 1 : year;
    },
    getReferenceYearComputed(type, material, month) {
      // Construct a unique key for the cache
      const cacheKey = `${type}-${material}-${month}`;

      // Check if the result is in cache
      if (this.referenceYearComputedCache[cacheKey] !== undefined) {
        return this.referenceYearComputedCache[cacheKey];
      }

      let year;
      let refval;
      switch (type) {
        case "ref_material":
          refval = this.getData(this.refYears, { ref_material: material }).ref_begin_year;

          break;
        case "ref_prev_material":
          refval = this.getData(this.refYears, { ref_prev_material: material }).ref_prev_begin_year;

          break;
        case "ref_upc":
          refval = this.getData(this.refYears, { ref_upc: material }).ref_begin_year;
          break;
        case "ref_prev_upc":
          refval = this.getData(this.refYears, { ref_prev_upc: material }).ref_prev_begin_year;
          break;
        default:
          year = 2022;
      }
      year = month < 4 ? refval + 1 : refval;
      // Store the result in cache before returning it
      this.referenceYearComputedCache[cacheKey] = year;

      return year;
    },

    getRefUpcMonthlyTotal(type, data) {
      let units = 0;
      for (let i = 0; i < this.season.months.length; i++) {
        let month = this.season.months[i];
        let upcnum = data.ref_upc;
        let year = this.adjustYear(i, new Date(data.begin_algorithm_date).getFullYear());
        if (type === "ref_prev_upc") {
          upcnum = data.ref_prev_upc;
          year = this.adjustYear(i, new Date(data.ref_prev_begin_algorithm_date2).getFullYear());
        }
        units += this.refSelthru
          .filter((x) => x.upc_number == upcnum && x.year == year && x.month == month)
          .reduce((total, item) => total + item.units_sold, 0);
      }

      return units;
    },

    getMaterial(material) {
      //console.log("getMaterial", material);
      const mat = this.$materialAllocation.getMaterial(material.material);
      return mat;
    },

    getMaterialFromMatNumb(material) {
      const mat = this.$materialAllocation.getMaterial(material);
      return mat;
    },

    getPlannedUnitsByMonth(month) {
      const val = this.$materialAllocation.plannedUnitsByMonthForAllOrdertypes(month, true);
      return val;
    },

    setPlannedUpcValue(material, variant, month, event) {
      const value = !event.target.value ? 0 : parseInt(event.target.value);
      this.$materialAllocation.setPlannedUpcValue(material.material, variant.upcNumber, month, value, true);
    },

    getCustomSumByMaterial(material) {
      if (Object.keys(this.variantUnits).length === 0) {
        return material["material_total"];
      } else {
        return Object.keys(this.variantUnits)
          .filter((key) => key.includes(material.material))
          .reduce((acc, key) => acc + this.variantUnits[key], 0);
      }
    },
    getCustomSumByVariant(variant) {
      return Object.keys(this.variantUnits)
        .filter((key) => key.includes(variant))
        .reduce((acc, key) => acc + this.variantUnits[key], 0);
    },
    getMonthlyTotalDisplay(monthly_total, month, material) {
      let total = Math.floor(
        this.getData(this.suggestedBookings, { month: month, type: material.type, segment: material.segment }).units *
          material.ratio
      );
      if (monthly_total == 0 && total > 0) {
        return total;
      } else {
        return monthly_total;
      }
    },
    scrollToTop(id) {
      let obj = document.getElementById(id);
      if (obj.scrollTop > 1) {
        obj.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        obj.scrollTo({
          top: obj.scrollHeight,
          behavior: "smooth",
        });
      }
    },
    getCustomUnitValue(material, monthIndex, weight, variant) {
      monthIndex = this.getActualMonthIndex(monthIndex);

      if (this.variantUnits[`${variant.upc}-${monthIndex}-${material}`] != null) {
        return this.variantUnits[`${variant.upc}-${monthIndex}-${material}`];
      }

      const foundInCustom = this.$store.state.customSavedAllocationValues[material];
      let doCalculated = false;

      if (foundInCustom != null) {
        const foundMonth = foundInCustom.find((x) => x["month_index"] === monthIndex);
        if (foundMonth != null) {
          this.variantUnits[`${variant.upc}-${monthIndex}-${material}`] = Math.round(
            foundMonth["units_entered"] * weight
          );
          return Math.round(foundMonth["units_entered"] * weight);
        } else {
          doCalculated = true;
        }
      } else {
        doCalculated = true;
      }

      if (doCalculated) {
        const calculated = this.$store.state.calculatedAllocationValues[material];
        const foundMonth = calculated.find((x) => x["month_index"] === monthIndex);
        if (foundMonth != null) {
          this.variantUnits[`${variant.upc}-${monthIndex}-${material}`] = Math.round(foundMonth["units"] * weight);
          return Math.round(foundMonth["units"] * weight);
        } else {
          return 0;
        }
      }
    },
    getBeginSeasonFilter() {
      if (this.product.level1_bth_or_holiday_id.toUpperCase() === "HOLIDAY") {
        this.begin_season_filter_str = "Holiday";
      }
    },
    toggle() {
      this.visible = !this.visible;
    },
    selectUpcAllocation(event, option) {
      this.$materialAllocation.stepTwoDirty = true;
      if (this.selectedValue !== option.value) {
        this.selectedValue = option.value;
        this.resetMaterialData();
        this.loadFirstTime = false;
        switch (option.value) {
          case 222:
          case 111:
          case 220:
          case 112:
          case 224:
          case 777:
            break;
          case 113:
          case 221:
            break;
          case 114:
          case 225:
            break;
          case 115:
          case 223:
            break;
        }
        this.value = option.description;
        this.$store.commit("setSelectedAllocationMethod", option.description);
        this.activeSellthruItems = [];
        this.filter = option.value;
        this.fetchUpc();
      }
    },
    // GET THE REF YEAR
    getReferenceYear(type, id, month) {
      let year = 0;
      switch (type) {
        case "ref_material":
          year = this.getData(this.refYears, { ref_material: id }).ref_begin_year;
          break;
        case "ref_prev_material":
          year = this.getData(this.refYears, { ref_prev_material: id }).ref_prev_begin_year;
          break;
        case "ref_upc":
          year = this.getData(this.refYears, { ref_upc: id }).ref_begin_year;
          break;
        case "ref_prev_upc":
          year = this.getData(this.refYears, { ref_prev_upc: id }).ref_prev_begin_year;
          break;
        default:
          year = 2022;
      }
      if (month == 1 || month == 2 || month == 3) {
        year = year + 1;
      }
      return year;
    },
    getDataForFilter(v) {
      switch (v) {
        case 112:
          return this.sellThruData;
        case 113:
          return this.countryData;
        case 114:
          return this.regionData;
        case 115:
          return this.marketData;
        default:
          return [];
      }
    },
    // CALLED WHEN UPC DROPDOWN IS SELECTED
    //fetchUPC does not require a startDate and endDate and other parameters as well : TODO
    async fetchUpc() {
      this.$overlay.show("Loading upc data...");
      let data = [];
      const storeFetchUpcVal = [112, 113, 114, 115];
      const variables = {
        soldto_id: this.retailer.soldto_id,
        category: this.product.id,
        category_value: this.product.category_value,
        planning_year: this.currentSeasonYear,
        start_month: 0,
        end_month: 0,
        sorg: this.retailer.sorg,
        currency_type: "PR00",
        smu_group_sec: this.retailer.SEC,
        smu_group_sdc: this.retailer.SDC,
        market: this.retailer.market,
        filter: this.filter,
        market_filter: this.retailer.marketQuery,
        region: this.retailer.region,
        start_date: this.startDate,
        end_date: this.endDate,
        begin_season_filter_str: this.begin_season_filter_str,
        top_planning_input_id: this.product.top_planning_inputs_id,
        end_year: this.currentSeasonYear - 1,
        category_id: this.product.category_id,
        ship_to_id: this.retailer.shipto_id,
      };

      if (storeFetchUpcVal.indexOf(this.filter) !== -1) {
        if (!this.$materialAllocation.areUPCsInitialized) {
          data = await this.fixMarketData(variables);
        } else {
          data = await this.getDataForFilter(this.filter);
        }
      } else {
        // need this also for load last saved data to obtain upc dimensions and sizes

        if (!this.$materialAllocation.areUPCsInitialized) {
          await this.fixMarketData(variables);
        }

        variables.filter = this.filter;
        const result = await API.graphql(graphqlOperation(fetchUpc, variables));
        data = result.data.fetchUpc;
      }

      this.variantData = data;
      this.localMaterialData = JSON.parse(JSON.stringify(this.materialData));
      this.localMonthlyTotals = [];
      this.remaindersByMaterialNumber = {};
      this.localMaterialData.forEach((item) => {
        this.showElements = { ...this.showElements, [`hideRows-material-${item.material}`]: false };
      });

      this.$store.commit("setBookingData", this.localMaterialData);

      if (this.filter === 777 && !this.$materialAllocation.isSavedUpcLoaded) {
        let savedData = [];
        const savedUpcData = await API.graphql({
          query: loadUpcData,
          variables: {
            soldto_id: this.retailer.soldto_id,
            category: this.product.id,
            season: this.currentSeasonMutation,
          },
        });
        savedData = savedUpcData.data.loadUpcData;
        if (savedData.length > 0) {
          this.$materialAllocation.initUPCFromSavedData(savedData, parseInt(this.season.year));
          this.isSaveDisabled = false;
        } else {
          this.isSaveDisabled = true;
        }
      } else {
        if (data.length > 0) {
          this.isSaveDisabled = false;
        } else {
          this.isSaveDisabled = true;
        }
      }

      //there are no weights for last saved data just the hard data.
      this.variantData.forEach((variant) => {
        this.$materialAllocation.updateUpcWeight(
          variant.material,
          variant.upc,
          this.filter !== 777 ? variant.weight : 0
        );
      });

      this.$materialAllocation.doUPCAllocation(this.filter === 777);

      if (this.loadFirstTime) {
        this.recalculateSummary();
        // USED IN UpcReview
        this.$store.commit("setAllUpcData", this.localMaterialData);
      }

      setTimeout(this.$overlay.hide, 1000);
    },

    async fixMarketData(variables) {
      variables.filter = 115;
      const market = await API.graphql(graphqlOperation(fetchUpc, variables));
      const mdata = market.data.fetchUpc;
      this.$materialAllocation.initializeUpcs(mdata);
      mdata.forEach((variant) => {
        this.$materialAllocation.updateUpcWeight(variant.material, variant.upc, variant.weight, true);
      });
      this.$store.commit("setMarketData", mdata);
      console.warn("Market data had to be reloaded");
      return mdata;
    },

    // RESETS STATE ON COMPONENT CREATE AND UPC ALLOCATION DROPDOWN SELECT
    resetMaterialData() {
      this.variantData = [];
      this.localMaterialData = [];
      this.localMaterialIndex = [];
      this.localMonthlyTotals = [];
      if (this.resetMaterial) {
        for (let i = 0; i < this.materialData.length; i++) {
          let material = this.materialData[i];
          material.showMaterial = false;
          material.data = [];
          material.material_total = 0;
          // material.monthly_totals = Array(this.months.length).fill(0);
        }
      }
      // NOTE THIS IS ONLY FALSE AT COMPONENT CREATE AND THEN ALWAYS TRUE
      this.resetMaterial = true;
    },

    getActualMonthIndex(month) {
      return this.monthPositions[month];
    },
    getMonthIndexFromNormalMonth(month) {
      return this.monthPositions.indexOf(month);
    },
    checkDeadline(month) {
      // TODO THIS SHOULD ALSO CHECK THE PRODUCT LAUNCH DATE
      var index = 0;
      if (typeof month.value !== "undefined" && month.value !== null) {
        index = month.value;
      } else {
        index = this.months.indexOf(month);
        if (index == -1) {
          index = month;
        }
      }

      if (index == 1 || index == 2 || index == 3) {
        return false;
      }

      if (index < this.earliestMonthCanPlanGlobal) {
        return true;
      } else {
        return false;
      }
    },
    // CALLED WHEN MATERIAL BREAKDOWN OR PREVIOUS SELLTHRU LINKS ARE CLICKED
    // FETCHES DATA ON-DEMAND RATHER THAN BY DEFAULT
    toggleClass(event, id, className, isPreviousSellthru, refMaterial) {
      const newmaterial = parseInt(id.split("-")[1], 10);
      if (refMaterial === "error" && className === "hideDetails") {
        this.$warning.show(event, "WARNING: There is no reference data for this item", 100);
        setTimeout(this.$warning.hide, 1000);
        return;
      }

      if (className == "hideDetails") {
        const found = this.activeSellthruItems.find((element) => element === newmaterial);
        if (found != null) {
          this.activeSellthruItems.splice(this.activeSellthruItems.indexOf(found), 1);
        } else {
          this.activeSellthruItems.push(newmaterial);
        }
      }

      if (this.showElements[`${className}-${id}`]) {
        this.$set(this.showElements, `${className}-${id}`, false); // Hide the elements
      } else {
        this.$set(this.showElements, `${className}-${id}`, true); // Show the elements
      }
      if (isPreviousSellthru) {
        if (!this.currentlyToggledPreviousSellThruMaterials[newmaterial]) {
          this.currentlyToggledPreviousSellThruMaterials[newmaterial] = true;
        } else {
          this.currentlyToggledPreviousSellThruMaterials[newmaterial] =
            !this.currentlyToggledPreviousSellThruMaterials[newmaterial];
        }
        if (
          this.loadedSellThruData.includes(refMaterial[0].ref_material) ||
          this.loadedSellThruData.includes(refMaterial[0].ref_prev_material)
        ) {
          document.getElementById(id).classList.toggle(className);
        } else {
          this.getPreviousSellThruUnits(refMaterial, id, className);
        }
      } else {
        const ret = document.getElementById(id).classList.toggle(className);

        if (!ret) {
          this.$nextTick(() => {
            this.$emit("upcShown", {
              materialName: newmaterial,
              refMaterial: refMaterial[0],
            });
          });
        }
      }
    },

    updateUpcTotals(material) {
      const upcTotals = this.$materialAllocation.getPlannedUpcValuesForYear(material.materialName);
      upcTotals.forEach((upctotal) => {
        const vpt = document.getElementById(`vpt-${material.materialName}-${upctotal.upcNumber}`);
        if (vpt != null) {
          vpt.textContent = upctotal.total;
        }
        const yvp = document.getElementById(`yvp-${material.materialName}-${upctotal.upcNumber}`);
        if (yvp != null) {
          yvp.textContent = this.formatWithCommas(
            this.divideByThousand(
              (upctotal.total +
                this.actuals
                  .filter((x) => x.upc_number == upctotal.upcNumber)
                  .reduce((sum, currentItem) => sum + currentItem.units_sold, 0)) *
                upctotal.price
            )
          );
        }
      });
      const gsop = document.getElementById(`gsop-${material.materialName}`);
      if (gsop !== null) {
        const val = this.$materialAllocation.plannedPriceByYearForMaterialWithActuals(
          material.materialName,
          true,
          this.actuals
            .filter((x) => x.material_number == material.materialName)
            .reduce((sum, currentItem) => sum + currentItem.units_sold, 0)
        );
        gsop.textContent = this.formatWithCommas(this.divideByThousand(val));
      }
    },

    getSumForSelectedMonths(data, type, id) {
      let units = 0;
      for (let i = 0; i < this.season.months.length; i++) {
        let month = this.season.months[i];
        if (month == 1 || month == 2 || month == 3) {
          if (data == "material") {
            units += this.getSum(
              this.refSelthru,
              { material: id, year: this.getReferenceYear(type, id) + 1, month: month },
              "units_sold"
            );
          } else {
            units += this.getSum(
              this.refSelthru,
              { upc_number: id, year: this.getReferenceYear(type, id) + 1, month: month },
              "units_sold"
            );
          }
        } else {
          if (data == "material") {
            units += this.getSum(
              this.refSelthru,
              { material: id, year: this.getReferenceYear(type, id), month: month },
              "units_sold"
            );
          } else {
            units += this.getSum(
              this.refSelthru,
              { upc_number: id, year: this.getReferenceYear(type, id), month: month },
              "units_sold"
            );
          }
        }
      }
      return units;
    },
    // CALLED WHEN COMPONENT IS CREATED
    // RETURNS PRIOR YEARS REFERENCE DATA
    async getAllReferenceUpcs() {
      const response = await API.graphql({
        query: getAllReferenceUpcs,
        variables: {
          category: this.product["category_value"],
          sorg: this.retailer.sorg,
        },
      });
      const data = response.data.getAllReferenceUpcs;
      this.refUpcs = data;
    },
    // RETURNS PREVIOUS REF YEARS
    async getRefYears() {
      const response = await API.graphql({
        query: getRefYears,
        variables: {
          category: this.product["category_value"],
        },
      });
      const data = response.data.getRefYears;
      this.refYears = data;
    },
    // CALLED WHEN PREVIOUS SELLTHROUGH TOGGLE LINKS ARE CLICKED
    async getPreviousSellThruUnits(refdata, id, className) {
      const refMaterials = [...new Set(refdata.map((item) => item.ref_material))];
      // const refPrevMaterials = [
      //   ...new Set(refdata.map((item) => (item.ref_prev_material === null ? 0 : parseInt(item.ref_prev_material)))),
      // ];
      const refPrevMaterials = [
        ...new Set(
          refdata.reduce((acc, item) => {
            if (item.ref_prev_material) {
              return [...acc, item.ref_prev_material];
            }
          }, [])
        ),
      ];
      // Convert arrays to comma-separated strings with single quotes around each element
      const refMaterialsStr = refMaterials.join(",");
      const refPrevMaterialsStr = refPrevMaterials.join(",");
      const response = await API.graphql({
        query: getPreviousSellThruUnits,
        variables: {
          soldto_id: this.retailer.soldto_id,
          category: this.product.category_id,
          ref_material: refMaterialsStr,
          ref_prev_material: refPrevMaterialsStr,
          start_date: `${this.currentSeasonYear - 4}-04-01`,
          end_date: `${this.currentSeasonYear}-03-31`,
          begin_season_filter_str: this.begin_season_filter_str,
          top_planning_input_id: this.product.top_planning_inputs_id,
        },
      });
      const data = response.data.getPreviousSellThruUnits;
      //Some ref_prev_materials are '0' , remove them from the list
      const arr_ref_prev_materials = refdata[0].ref_prev_material === "0" ? [] : [refdata[0].ref_prev_material];
      this.loadedSellThruData = [...this.loadedSellThruData, refdata[0].ref_material, ...arr_ref_prev_materials];
      const tmp = this.refSelthru;
      this.refSelthru = [...tmp, ...data];
      document.getElementById(id).classList.toggle(className);
      //get all the upcs fopr the toggled material
      if (refdata.length > 0) {
        this.updateReferenceValues(refdata[0]);
      }
    },

    updateReferenceValues(refdata) {
      const upcs = this.refUpcs.filter((x) => x.new_material_number == refdata.new_material_number);
      upcs.forEach((upc) => {
        const reftot = document.getElementById(`tot_ref_upc-${upc.ref_upc}`);
        let reftotunits = 0;

        const prevreftot = document.getElementById(`tot_ref_prev_upc-${upc.ref_prev_upc}`);
        let prevreftotunits = 0;
        for (let i = 1; i < 13; i++) {
          //process ref_upc
          let year = this.adjustYear(i, new Date(upc.begin_algorithm_date).getFullYear());
          let target = document.getElementById(`refupc-${upc.ref_upc}-${i}-${year}`);
          const result = this.refSelthru.reduce((total, item) => {
            if (item.upc_number == upc.ref_upc && item.year == year && item.month == i) {
              return total + item.units_sold;
            }
            return total;
          }, 0);

          reftotunits += result;
          if (target) target.innerText = result;

          //processr ref_prev_upc
          const prevyear = this.adjustYear(i, new Date(upc.ref_prev_begin_algorithm_date2).getFullYear());
          const prevtarget = document.getElementById(`refprevupc-${upc.ref_prev_upc}-${i}-${prevyear}`);
          const prevresult = this.refSelthru.reduce((total, item) => {
            if (item.upc_number == upc.ref_prev_upc && item.year == prevyear && item.month == i) {
              return total + item.units_sold;
            }
            return total;
          }, 0);
          prevreftotunits += prevresult;
          if (prevtarget) {
            prevtarget.innerText = prevresult;
          }
        }
        if (reftot && prevreftot) {
          reftot.innerText = reftotunits;
          prevreftot.innerText = prevreftotunits;
        }
      });
    },

    // SAVE UPC DATA BUTTON EVENT HANDLER
    async saveUpcData(gotosettings) {
      this.lastUpdated = "";
      this.pageError = "";
      if (this.isSaveDisabled) {
        if (this.$store.state.wasLastSaveFromSaveButton) {
          this.$overlay.show("Nothing to save");
          setTimeout(this.$overlay.hide, 2000);
        }
      } else {
        try {
          const dataToBeSaved = this.prepareUpcSave();
          if (dataToBeSaved.length) {
            await API.graphql(graphqlOperation(saveUpcData, { saveUpcData: dataToBeSaved }));
          } else {
            await API.graphql(
              graphqlOperation(deleteSavedUpcOrder, {
                soldto_id: this.retailer.soldto_id,
                season: this.currentSeasonMutation,
                modified_by: this.user.name,
                category: this.product.id,
              })
            );
          }
          this.lastUpdated = new Date().toLocaleString();
          this.$materialAllocation.stepTwoDirty = false;
        } catch (err) {
          this.pageError = err;
        }
      }

      if (this.pageError != "") {
        this.$overlay.show("Error saving data, try again.");
        setTimeout(this.$overlay.hide, 2000);
      } else if (!this.isSaveDisabled && this.lastUpdated !== "") {
        if (this.$store.state.wasLastSaveFromSaveButton) {
          this.$overlay.show("Last Saved at: " + this.lastUpdated);
          setTimeout(this.$overlay.hide, 2000);
        }
      }

      if (gotosettings && gotosettings.gotosettings) {
        this.$router.push("./setup");
      }
    },
    // PACKAGES DATA FOR saveUpcData()
    prepareUpcSave() {
      let upcData = this.$materialAllocation.packageUpcForSave(
        this.retailer.soldto_id,
        this.product.id,
        this.currentSeasonMutation,
        this.user.name,
        parseInt(this.season.year)
      );

      return upcData;
    },

    // AGGREGATES DATA USED IN SUMMARY COMPONENT
    recalculateSummary() {
      this.localMaterialFamily = [];
      this.localMaterialGender = [];
      this.localMaterialSegment = [];
      this.localMaterialDim = [];

      this.localMaterialData.forEach((element) => {
        this.calcLocalGroupingTotals(element, element.family, this.localMaterialFamily, "family", element);
        this.calcLocalGroupingTotals(element, element.gender, this.localMaterialGender, "gender", element);
        this.calcLocalGroupingTotals(element, element.segment, this.localMaterialSegment, "segment", element);

        element.data.forEach((childelement) => {
          this.calcLocalGroupingTotals(childelement, childelement.dim1, this.localMaterialDim, "dim1", element);
          if (this.product === "Ice Hockey Sticks") {
            this.calcLocalGroupingTotals(childelement, childelement.dim2, this.localMaterialDim, "dim2", element);
          }
        });
      });

      // TODO GET THIS WORKING AGAIN
      this.$store.commit("setUpcFamily", this.localMaterialFamily);
      this.$store.commit("setUpcGender", this.localMaterialGender);
      this.$store.commit("setUpcSegment", this.localMaterialSegment);
      this.$store.commit("setUpcDim", this.localMaterialDim);
      this.$store.commit("setRefreshData", false);
    },
    // UTILITY FUNCTION FOR AGGREGATING SUMMARY DATA
    calcLocalGroupingTotals(data, value, selector, groupby, material) {
      const checkIndex = this.checkIfGroupExistis(value, selector, groupby);
      let total_units = material.material_total;

      if (groupby === "dim1" || groupby === "dim2") {
        total_units = data.total;
      }

      if (checkIndex < 0) {
        let model = {
          [groupby]: "",
          data: [
            { type: "launch", units: 0, amount: 0 },
            { type: "migrating", units: 0, amount: 0 },
          ],
        };
        model[groupby] = data[groupby];
        if (material.type === "launch") {
          model.data[0].units = total_units;
          model.data[0].amount = total_units * material.amount;
        } else if (material.type === "migrating") {
          model.data[1].units = total_units;
          model.data[1].amount = total_units * material.amount;
        }
        selector.push(model);
      } else {
        if (material.type === "launch") {
          selector[checkIndex].data[0].units += total_units;
          selector[checkIndex].data[0].amount += total_units * material.amount;
        } else if (material.type === "migrating") {
          selector[checkIndex].data[1].units += total_units;
          selector[checkIndex].data[1].amount += total_units * material.amount;
        }
      }
    },
    checkIfGroupExistis(data, selector, groupby) {
      let index = -1;
      for (let i = 0; i < selector.length; i++) {
        if (data === selector[i][groupby]) {
          index = i;
        }
      }
      return index;
    },
    exportUpcData() {
      const exportObject = this.$materialAllocation.exportUpcData();

      // TODO FILENAME SHOULD BE PROGRAMMATICALLY CREATED WITH STATE VARIABLES
      const filename =
        `${this.currentSeason}_BTH_UPC_` +
        this.retailer.soldto_id +
        "_" +
        new Date(Date.now()).toLocaleString() +
        ".csv";
      let csvContent = "data:text/csv;charset=utf-8,";
      try {
        csvContent += [
          Object.keys(exportObject[0]).join(","),
          ...exportObject.map((item) => Object.values(item).join(",")),
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");
      } catch (e) {
        this.$overlay.show("There is no data to export. Please select an allocation method and try again");
        setTimeout(this.$overlay.hide, 2000);
        return;
      }
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", filename);
      link.click();
    },
    // NOTE THIS IS USED IN WATCHER
    updateFinalUpcData() {
      this.$store.commit("setAllUpcData", this.localMaterialData);
      this.localMonthlyTotals.forEach(({ units, amount }, i) => {
        this.$store.commit("setAdjustedBooking", { index: i, units: units, gross: amount });
      });
    },
    formatWithCommas(number) {
      return number.toLocaleString("en-US", { maximumFractionDigits: 0 });
    },
    divideByThousand(value) {
      if (!value) {
        return 0;
      }
      const result = value / 1000;
      return value < 1000 ? result.toFixed(3) : result.toFixed(2);
    },
  },
  beforeDestroy() {
    this.$root.$off("saveBookings");
    this.$root.$off("updatedConfig");
    this.$store.commit("setSelectedAllocationMethod", "");
    this.$materialAllocation.$off("upcChanged");
  },
  mounted() {
    this.$materialAllocation.stepTwoDirty = false;
    this.getBeginSeasonFilter();
    this.$on("upcShown", (data) => {
      setTimeout(this.updateUpcTotals(data, 1000));
      setTimeout(this.updateReferenceValues(data.refMaterial, 1000));
    });
    this.$materialAllocation.$on("upcChanged", (data) => {
      this.$overlay.show("Updating...");

      for (let monthIndex = 1; monthIndex < 13; monthIndex++) {
        const open = document.getElementById(`oo-${data.materialName}-${monthIndex}`);
        if (open !== null && !open.hasAttribute("ooset")) {
          const val = this.actuals
            .filter((x) => x.selling_month === monthIndex && x.material_number == data.materialName)
            .reduce((sum, currentItem) => sum + currentItem.units_sold, 0);
          open.textContent = val;
          open.setAttribute("ooset", "true");
        }

        const oa = document.getElementById(`oa-${data.materialName}-${monthIndex}`);
        if (oa !== null && !open.hasAttribute("oaset")) {
          oa.textContent = this.actuals
            .filter((x) => x.selling_month === monthIndex && x.material_number == data.materialName)
            .reduce((sum, currentItem) => sum + currentItem.units_sold, 0);
        }
      }

      const oap = document.getElementById(`oap-${data.materialName}-${data.month}`);
      if (oap !== null) {
        oap.textContent =
          data.upcPlanned +
          this.actuals
            .filter((x) => x.selling_month === data.month && x.material_number == data.materialName)
            .reduce((sum, currentItem) => sum + currentItem.units_sold, 0);
      }

      const yop = document.getElementById(`yop-${data.materialName}`);
      if (yop !== null) {
        yop.textContent =
          this.getPlannedUnitsByYearByMaterial(data.materialName) +
          this.actuals
            .filter((x) => x.material_number == data.materialName)
            .reduce((sum, currentItem) => sum + currentItem.units_sold, 0);
      }

      if (data.upcNumber !== null) {
        const vpt = document.getElementById(`vpt-${data.materialName}-${data.upcNumber}`);
        if (vpt !== null) {
          vpt.textContent = this.$materialAllocation.getPlannedUpcValueForYear(data.materialName, data.upcNumber);
        }
        const yvp = document.getElementById(`yvp-${data.materialName}-${data.upcNumber}`);
        if (yvp !== null) {
          yvp.textContent = this.formatWithCommas(
            this.divideByThousand(
              this.$materialAllocation.getPlannedUpcPriceForYear(
                data.materialName,
                data.upcNumber,
                this.actuals
                  .filter((x) => x.upc_number == data.upcNumber)
                  .reduce((sum, currentItem) => sum + currentItem.units_sold, 0)
              )
            )
          );
        }
      }

      const gsop = document.getElementById(`gsop-${data.materialName}`);
      if (gsop !== null) {
        gsop.textContent = this.formatWithCommas(
          this.divideByThousand(
            this.$materialAllocation.plannedPriceByYearForMaterialWithActuals(
              data.materialName,
              true,
              this.actuals
                .filter((x) => x.material_number == data.materialName)
                .reduce((sum, currentItem) => sum + currentItem.units_sold, 0)
            )
          )
        );
      }

      const yom = document.getElementById(`yom-${data.materialName}`);
      if (yom !== null) {
        yom.textContent = this.actuals
          .filter((x) => x.material_number == data.materialName)
          .reduce((sum, currentItem) => sum + currentItem.units_sold, 0);
      }

      const ypm = document.getElementById(`ypm-${data.materialName}`);
      if (ypm !== null) {
        ypm.textContent = this.getPlannedUnitsByYearByMaterial(data.materialName);
      }

      const mopi = document.getElementById(`mopi-${data.materialName}-${data.month}`);
      if (mopi !== null) {
        mopi.textContent = `(${data.planned})`;
        mopi.className = data.planned !== data.upcPlanned ? "highlight" : "";
      }

      const cur = document.getElementById(`m-${data.materialName}-${data.month}`);
      if (cur !== null) cur.textContent = data.upcPlanned;

      const mmpl = document.getElementById(`mmpl-${data.month}`);
      if (mmpl !== null)
        mmpl.textContent = this.formatWithCommas(
          this.$materialAllocation.plannedUnitsByMonthForAllOrdertypes(data.month, true) +
            this.getSum(this.actuals, { selling_month: data.month }, "units_sold")
        );

      const mmpp = document.getElementById(`mmpp-${data.month}`);
      if (mmpp !== null)
        mmpp.textContent = this.formatWithCommas(
          this.divideByThousand(
            this.$materialAllocation.plannedPriceByMonthForAllOrdertypes(data.month, true) +
              this.getSum(this.actuals, { selling_month: data.month }, "amount")
          )
        );

      const ympl = document.getElementById(`ympl`);
      if (ympl !== null)
        ympl.textContent = this.formatWithCommas(
          this.$materialAllocation.plannedUnitsByYearForAllOrdertypes(true) +
            this.getSum(this.actuals, {}, "units_sold")
        );

      const ympp = document.getElementById(`ympp`);
      if (ympp !== null)
        ympp.textContent = this.formatWithCommas(
          this.divideByThousand(
            this.$materialAllocation.plannedPriceByYearForAllOrdertypes(data.month, true) +
              this.getSum(this.actuals, {}, "amount")
          )
        );
      this.$overlay.hide();
    });

    const thisInstance = this;
    this.$root.$on("saveBookings", function (gotosettings) {
      thisInstance.saveUpcData(gotosettings);
    });

    this.$root.$on("updatedConfig", function () {
      thisInstance.selectedValue = 0;
      thisInstance.localMonthlyTotals = [];
      thisInstance.variantData = [];
      thisInstance.localMaterialData = [];
      thisInstance.refUpcs = [];
    });

    const option1 = this.list.find((x) => x.value == 112);
    const option2 = this.list.find((x) => x.value == 113);
    const option3 = this.list.find((x) => x.value == 114);
    const option4 = this.list.find((x) => x.value == 115);
    const optionBookings = this.list.find((x) => x.value == 111);
    const optionLastSavedData = this.list.find((x) => x.value == 777);

    option1.description += ` - ${this.retailer.soldto_id}`;
    option2.description += ` - ${this.retailer.sorg}`;
    option3.description += ` - ${this.retailer.region}`;
    option4.description += ` - ${this.retailer.market}`;

    optionBookings.description += ` - ${this.retailer.soldto_id}`;
    optionLastSavedData.description += ` - ${this.retailer.soldto_id}`;
  },
};
</script>

<style scoped lang="scss">
$header_column_width: 250px;
$input_column_width: 75px;

.labels_th {
  min-width: $header_column_width;
  width: $header_column_width;
}

.leftRightBorderDarker {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  text-align: center !important;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.alignAndBorder {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  text-align: center !important;
}

.allBorders {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.justLeftBorderDarker {
  border-left: 1px solid black !important;
  border-right: 1px solid #dadada;
  border-bottom: 1px solid black;
  border-top: 1px solid black !important;
  text-align: center !important;
}

.actuallyOnlyLeft {
  border-left: 1px solid black !important;
}

.justRightBorderDarker {
  border-right: 1px solid black !important;
  border-left: 1px solid #dadada;
  border-bottom: 1px solid black;
  border-top: 1px solid black !important;
  text-align: center !important;
  width: 1%;
}

.dataTd {
  border-top: none !important;
  padding: 0px !important;
}

.table td,
.table th {
  padding: 0.75rem 0.25rem;
}

.hideable th {
  padding: 0 2px;
}

.paddingCell {
  th {
    padding: 0 2px;
  }
}

.stickyTableHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 1.5em;
  background-color: #f8f9fa;
  width: 100%;
  z-index: 500;
}

.horizontal__scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #b6b6b6;
  display: none;
}

.horizontal__scroll::-webkit-scrollbar {
  display: none;
  width: 10px;
  background-color: #b6b6b6;
}

.upcContainer {
  margin-top: 50px;
}

.horizontal__scroll::-webkit-scrollbar-thumb {
  margin-top: 25px;
  border-radius: 10px;
  background-color: #009acd;
  display: none;
}

.horizontal__scroll {
  border: 2px solid #dadada;
  margin-top: 10px;
}

.table {
  margin-top: 0 !important;
}

.tableTopInformation {
  padding-top: 0.2em;
  font-size: 1.2em;
  line-height: 1.2em;
  background-color: #f8f9fa;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 500;
}

#scrollToTopBtn {
  background-color: #009acd;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: 48px;
  width: 48px;
  position: -webkit-sticky;
  position: sticky;
  left: 96%;
  bottom: 1em;
}
</style>
